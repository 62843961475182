<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <div v-if="isSoftwarePartner || isSuperAdmin" class="mb-2">
          <b-row align-v="end" class="pb-2">
            <!-- Software Partner Filter -->
            <b-col cols="12" lg="3" md="3" v-if="isSuperAdmin">
              <div>
                <small>{{ $t('admin.labs.opticsList.softwarePartner') }}</small>
                <v-select
                  v-model="selectedSoftwarePartner"
                  :options="filterSoftwarePartners"
                  :clearable="false"
                  label="nameRegistered"
                />
              </div>
            </b-col>

            <!-- Labs Filter -->
            <b-col
              cols="12"
              :lg="isSuperAdmin ? 3 : 4"
              :md="isSuperAdmin ? 3 : 4"
            >
              <div>
                <small>{{ $t('admin.labs.opticsList.lab') }}</small>
                <v-select
                  v-model="selectedLab"
                  :options="filterLabs"
                  :clearable="false"
                  label="nameRegistered"
                  :placeholder="$t('admin.labs.opticsList.myData')"
                />
              </div>
            </b-col>
            <!-- Optics Filter -->
            <b-col
              cols="12"
              :lg="isSuperAdmin ? 3 : 4"
              :md="isSuperAdmin ? 3 : 4"
            >
              <div>
                <small>{{ $t('admin.labs.sellersList.optic') }}</small>
                <v-select
                  v-model="selectedOptic"
                  :options="filterOptics"
                  :clearable="false"
                  label="nameRegistered"
                />
              </div>
            </b-col>

            <b-col>
              <b-button block variant="danger" @click="clearFilter()">
                {{ $t('admin.labs.opticsList.cleanFilter') }}
                <feather-icon icon="XIcon" />
              </b-button>
            </b-col>
          </b-row>
        </div>
        <b-row align-v="end" align-h="between">
          <!-- Per Page -->
          <b-col
            cols="12"
            lg="4"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('admin.labs.sellersList.show') }}</label>

            <v-select
              v-model="perPage"
              class="per-page-selector d-inline-block mx-50"
              :options="perPageOptions"
              :clearable="false"
            />

            <label>{{ $t('admin.labs.sellersList.entries') }}</label>
          </b-col>

          <b-col cols="12" lg="8" md="8"
            ><b-row align-v="end" align-h="end">
              <!-- Optics Filter -->
              <b-col cols="12" lg="4" md="4">
                <div v-if="isLabAdmin">
                  <small>{{ $t('admin.labs.sellersList.optic') }}</small>
                  <v-select
                    v-model="selectedOptic"
                    :options="filterOptics"
                    :clearable="false"
                    label="nameRegistered"
                  />
                </div>
              </b-col>

              <!-- Search -->
              <b-col cols="12" lg="4" md="4">
                <b-form-input
                  v-model="search"
                  class="d-inline-block"
                  :placeholder="$t('admin.labs.sellersList.searchBy')"
                />
              </b-col>

              <!-- Create -->
              <b-col
                cols="12"
                lg="4"
                md="4"
                v-if="!isLabAdmin && !isSoftwarePartner && !isSuperAdmin"
              >
                <b-button
                  block
                  variant="success"
                  :disabled="$store.getters['auth/inactive']"
                  @click="newSeller()"
                >
                  <span class="text-nowrap">{{
                    $t('admin.labs.sellersList.create')
                  }}</span>
                </b-button>
              </b-col>
            </b-row></b-col
          >
        </b-row>
      </div>

      <b-table
        ref="refSellersListTable"
        responsive
        show-empty
        primary-key="id"
        empty-text="No entries"
        class="position-relative"
        :items="filteredItems"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        :busy="$store.state.seller.loadingSellers"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <!-- Table Loading -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" small />

            <strong class="ml-1">{{
              $t('admin.labs.sellersList.loading')
            }}</strong>
          </div>
        </template>

        <template #cell(qtyMeasurements)="data">
          <span>{{
            `${data.item.qtyMeasurements}/${data.item.allowedMeasurements}`
          }}</span>
        </template>

        <template #cell(updatedAt)="data">
          <span>{{
            parseDateToString(data.item.updatedAt, 'mm dd yyyy')
          }}</span>
        </template>

        <template #cell(createdAt)="data">
          <span>{{
            parseDateToString(data.item.createdAt, 'mm dd yyyy')
          }}</span>
        </template>

        <template #cell(status)="data">
          <b-badge :variant="getStatusVariant(data.item)">
            <span>{{ getStatusText(data.item) }}</span>
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-row align-h="end">
            <b-button
              v-if="data.item.status != 'active'"
              size="sm"
              variant="outline-warning"
              :id="`email-${data.item.id}`"
              :disabled="
                $store.getters['auth/inactive'] || !!data.item.deletedAt
              "
              @click="sendSignLink(data.item.userEmail)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-tooltip
              triggers="hover"
              placement="bottom"
              :target="`email-${data.item.id}`"
            >
              <b>{{ $t('tooltips.sendInvitation') }}</b>
            </b-tooltip>

            <b-button
              size="sm"
              class="ml-1"
              variant="outline-secondary"
              :id="`edit-${data.item.id}`"
              :disabled="$store.getters['auth/inactive']"
              @click="editSeller(data.item)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-tooltip
              triggers="hover"
              placement="bottom"
              :target="`edit-${data.item.id}`"
            >
              <b>{{ $t('admin.labs.sellersList.edit') }}</b>
            </b-tooltip>

            <b-button
              class="ml-1"
              size="sm"
              :variant="
                canRestore(data.item) ? 'outline-success' : 'outline-danger'
              "
              :id="`delete-${data.item.id}`"
              :disabled="
                $store.getters['auth/inactive'] ||
                (data.item.deletedAt &&
                  data.item.deletedBy !== $store.state.auth.userId)
              "
              @click="
                () => {
                  if (data.item.deletedAt) {
                    return restoreSeller(
                      data.item.nameRegistered,
                      data.item.userEmail,
                      data.item.id,
                      getSellerParent(),
                    )
                  }

                  selectedItem = data.item
                  deleteModal = true
                }
              "
            >
              <feather-icon v-if="canRestore(data.item)" icon="RotateCcwIcon" />
              <feather-icon v-else icon="SlashIcon" />
            </b-button>
            <b-tooltip
              triggers="hover"
              placement="bottom"
              :target="`delete-${data.item.id}`"
            >
              <b>{{
                $t(
                  'admin.labs.sellersList.' +
                    (canRestore(data.item) ? 'restore' : 'delete'),
                )
              }}</b>
            </b-tooltip>
          </b-row>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              {{ $t('admin.labs.sellersList.totalOf') }} {{ totalItems }}
              {{ $t('admin.labs.sellersList.entries') }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              :per-page="perPage"
              :total-rows="totalItems"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <AlertDialog
      :message="$t('admin.labs.sellersList.deleteDialog.message')"
      :title="`${$t('admin.labs.sellersList.deleteDialog.title')} ${
        selectedItem.nameFantasy
      }`"
      :id="`confirmation-${selectedItem.id}`"
      :show="deleteModal"
      @action="
        deleteItem(selectedItem)
        selectedItem = {}
      "
      @close="deleteModal = false"
    />

    <AlertDialog
      :message="$t('admin.labs.sellersList.CreateDialog.message')"
      :title="$t('admin.labs.sellersList.CreateDialog.title')"
      :show="cannotCreateSellerModal"
      :warnOnly="true"
      @close="cannotCreateSellerModal = false"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormCheckbox,
  BButton,
  BTable,
  BLink,
  BBadge,
  BPagination,
  BSpinner,
  BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import AlertDialog from '@/components/AlertDialog.vue'

import { onUnmounted, ref } from 'vue-demi'
import store from '@/store'

import sellerStoreModule from './sellerStoreModule'
import useSellersList from './useSellersList'
import opticStoreModule from '@/views/sections/optics/opticStoreModule'
import useOpticsList from '@/views/sections/optics/useOpticsList'
import labStoreModule from '@/views/sections/labs/labStoreModule'
import useLabsList from '@/views/sections/labs/useLabsList'
import softwarePartnerStoreModule from '@/views/sections/software-partners/softwarePartnerStoreModule'
import useSoftwarePartnersList from '@/views/sections/software-partners/useSoftwarePartnersList'
import { parseDateToString } from '../../../utils/dates'
import useAuthLink from '../../auth/useAuthLink'
import RoleEnum from '@/utils/roles'
import {
  checkIfEntityHasSellersAvailable,
  getAllowedSellers,
} from '@/utils/plans'

export default {
  name: 'SellersList',

  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BLink,
    BBadge,
    BPagination,
    BSpinner,
    BTooltip,

    vSelect,
    AlertDialog,
  },

  data() {
    return {
      deleteModal: false,
      selectedItem: {},
      cannotCreateSellerModal: false,
      allowedSellers: null,

      parseDateToString,
    }
  },
  watch: {
    optics: {
      deep: true,
      handler() {
        if (this.isSoftwarePartner || this.isSuperAdmin) {
          this.selectedOptic = {
            me: true,
            nameRegistered: this.$i18n.t('admin.labs.opticsList.myData'),
          }
        } else this.selectedOptic = null
      },
    },
    labs: {
      deep: true,
      handler() {
        if (this.isSoftwarePartner || this.isSuperAdmin) {
          this.selectedLab = {
            me: true,
            nameRegistered: this.$i18n.t('admin.labs.opticsList.myData'),
          }
        } else this.selectedLab = null
      },
    },
    softwarePartners: {
      deep: true,
      handler() {
        if (this.isSuperAdmin) {
          this.selectedSoftwarePartner = {
            me: true,
            nameRegistered: this.$i18n.t('admin.labs.opticsList.myData'),
          }
        } else this.selectedSoftwarePartner = null
      },
    },
    selectedOptic(val) {
      if (val) this.refetchSellers(val)
      else this.refetchSellers({ me: true })
    },
    selectedLab(val) {
      if (val) {
        this.refetchSellers(val)
        this.refetchOptics({ me: val.me })
      } else this.refetchOptics({ me: true })
    },
    selectedSoftwarePartner(val) {
      if (val) {
        this.refetchLabs({ me: val.me })
      }
    },
  },

  computed: {
    isSoftwarePartner() {
      return this.user.role === RoleEnum.SOFTWARE_ADMIN
    },
    isLabAdmin() {
      return this.user.role === RoleEnum.LABORATORY_ADMIN
    },
    isSuperAdmin() {
      return this.user.role === RoleEnum.SUPER_ADMIN
    },
    activeSellers() {
      return this.sellers.filter((s) => !s.deletedAt)
    },
    filterOptics() {
      let items = []
      if (this.isLabAdmin || this.isSoftwarePartner || this.isSuperAdmin) {
        items = this.optics.filter((el)=>{
          return el.status == "active"
        })
      }
      return items
    },
    filteredItems() {
      return this.sellers.filter((el) =>
        el.nameRegistered.toLowerCase().includes(this.search.toLowerCase()),
      )
    },
    totalItems() {
      return this.filteredItems.length || 0
    },
    user() {
      return this.$store.state.auth.user
    },
    filterLabs() {
      let items = []
      if (this.isSoftwarePartner) {
        items = [
          {
            me: true,
            nameRegistered: this.$i18n.t('admin.labs.opticsList.myData'),
          },
        ].concat(this.labs.filter((el)=>{
          return el.status == "active"
        }))
      } else if (this.isSuperAdmin) {
        items = this.labs.filter((el)=>{
          return el.status == "active"
        })
      }

      return items
    },
    filterSoftwarePartners() {
      let items = []
      if (this.isSuperAdmin) {
        items = [
          {
            me: true,
            nameRegistered: this.$i18n.t('admin.labs.opticsList.myData'),
          },
        ].concat(this.softwarePartners.filter((el)=>{
          return el.status == "active"
        }))
      }

      return items
    },
  },

  methods: {
    checkIfEntityHasSellersAvailable,
    getAllowedSellers,
    canRestore(item) {
      return item.deletedAt && item.deletedBy === this.$store.state.auth.userId
    },
    clearFilter() {
      this.selectedLab = null
      this.selectedSoftwarePartner = null
      this.selectedOptic = null

      this.refetchSellers({ me: true })
    },
    async refetchSellers(item) {
      let path = ''

      if (item.me) {
        // Get the Sellers of the optic
        path = this.user.adminDocRef || this.user.sellerDocRef
      } else {
        if (this.isLabAdmin) {
          // Get the sellers of the optic
          path = `${this.user.adminDocRef}/optics/${this.selectedOptic.id}/`
        } else if (this.isSoftwarePartner) {
          if (this.selectedLab && this.selectedOptic) {
            path = `${this.user.adminDocRef}/laboratories/${this.selectedLab.id}/optics/${this.selectedOptic.id}`
          } else if (this.selectedLab) {
            path = `${this.user.adminDocRef}/laboratories/${this.selectedLab.id}`
          } else if (this.selectedOptic) {
            path = `${this.user.adminDocRef}/optics/${this.selectedOptic.id}`
          }
        } else if (this.isSuperAdmin) {
          if (
            this.selectedSoftwarePartner &&
            !this.selectedSoftwarePartner.me &&
            this.selectedLab &&
            !this.selectedLab.me &&
            this.selectedOptic &&
            !this.selectedOptic.me
          ) {
            path = `software-partners/${this.selectedSoftwarePartner.id}/laboratories/${this.selectedLab.id}/optics/${this.selectedOptic.id}`
          } else if (
            this.selectedSoftwarePartner &&
            !this.selectedSoftwarePartner.me &&
            this.selectedOptic &&
            !this.selectedOptic.me
          ) {
            path = `software-partners/${this.selectedSoftwarePartner.id}/optics/${this.selectedOptic.id}`
          } else if (
            this.selectedLab &&
            !this.selectedLab.me &&
            this.selectedOptic &&
            !this.selectedOptic.me
          ) {
            path = `laboratories/${this.selectedLab.id}/optics/${this.selectedOptic.id}`
          } else if (this.selectedOptic && !this.selectedOptic.me) {
            path = `optics/${this.selectedOptic.id}`
          }
        }
      }

      await this.$store.dispatch('seller/fetchSellers', path)
    },
    async refetchOptics(val) {
      const path = this.getOpticsParent(val)
      this.$store.dispatch('optic/fetchOptics', path)
    },
    async refetchLabs(val) {
      let path = ''
      if (this.isSuperAdmin) {
        if (!val.me) {
          if (this.selectedSoftwarePartner) {
            path = `software-partners/${this.selectedSoftwarePartner.id}`
          }
        }
      }

      this.$store.dispatch('laboratory/fetchLabs', path)
    },
    getStatusVariant(item) {
      if (item.deletedAt) return 'danger'

      switch (item.status) {
        case 'active':
          return 'success'
        case 'pending':
          return 'warning'
        default:
          return 'warning'
      }
    },
    getStatusText(item) {
      if (item.deletedAt) return this.$t('status.inactive')

      switch (item.status) {
        case 'active':
          return this.$t('status.active')
        case 'pending':
          return this.$t('status.pending')
        default:
          return this.$t('status.pending')
      }
    },
    editSeller(item) {
      const parent = this.getSellerParent()

      this.$router.push({
        name: 'seller-edit',
        params: { id: item.id },
        query: { p: parent },
      })
    },
    async newSeller() {
      // const r = this.activeSellers.length < this.allowedSellers
      const r = true

      if (r) {
        const parent = this.getSellerParent()
        this.$router.push({
          name: 'seller-edit',
          params: { id: 'new' },
          query: { p: parent },
        })
      } else {
        this.cannotCreateSellerModal = true
      }
    },
    deleteItem(item) {
      const parent = this.getSellerParent()
      this.deleteSeller(item.nameRegistered, item.userEmail, item.id, parent)
    },
    getOpticsParent(val) {
      let path = ''
      if (this.isSoftwarePartner) {
        if (!val.me) {
          if (this.selectedLab) {
            path = `${this.user.adminDocRef}/laboratories/${this.selectedLab.id}`
          }
        } else {
          path = `${this.user.adminDocRef}`
        }
      } else if (this.isSuperAdmin) {
        if (
          this.selectedSoftwarePartner &&
          !this.selectedSoftwarePartner.me &&
          this.selectedLab &&
          !this.selectedLab.me
        ) {
          path = `software-partners/${this.selectedSoftwarePartner.id}/laboratories/${this.selectedLab.id}`
        } else if (
          this.selectedSoftwarePartner &&
          !this.selectedSoftwarePartner.me
        ) {
          path = `software-partners/${this.selectedSoftwarePartner.id}`
        } else if (this.selectedLab && !this.selectedLab.me) {
          path = `laboratories/${this.selectedLab.id}`
        }
      }

      return path
    },
    getSellerParent() {
      let parent = ``
      if (this.isLabAdmin) {
        parent = `${this.user.adminDocRef}/optics/${this.selectedOptic.id}`
      } else if (this.isSoftwarePartner) {
        if (this.selectedLab && this.selectedOptic) {
          parent = `${this.user.adminDocRef}/laboratories/${this.selectedLab.id}/optics/${this.selectedOptic.id}`
        } else if (this.selectedLab) {
          parent = `${this.user.adminDocRef}/laboratories/${this.selectedLab.id}`
        } else if (this.selectedOptic) {
          parent = `${this.user.adminDocRef}/optics/${this.selectedOptic.id}`
        }
      } else if (this.isSuperAdmin) {
        if (
          this.selectedSoftwarePartner &&
          !this.selectedSoftwarePartner.me &&
          this.selectedLab &&
          !this.selectedLab.me &&
          this.selectedOptic &&
          !this.selectedOptic.me
        ) {
          parent = `software-partners/${this.selectedSoftwarePartner.id}/laboratories/${this.selectedLab.id}/optics/${this.selectedOptic.id}`
        } else if (
          this.selectedSoftwarePartner &&
          !this.selectedSoftwarePartner.me &&
          this.selectedOptic &&
          !this.selectedOptic.me
        ) {
          parent = `software-partners/${this.selectedSoftwarePartner.id}/optics/${this.selectedOptic.id}`
        } else if (
          this.selectedLab &&
          !this.selectedLab.me &&
          this.selectedOptic &&
          !this.selectedOptic.me
        ) {
          parent = `laboratories/${this.selectedLab.id}/optics/${this.selectedOptic.id}`
        }
      } else {
        parent = `${this.user.adminDocRef}`
      }

      return parent
    },
  },

  async mounted() {
    this.allowedSellers = await this.getAllowedSellers(this.user)
  },

  setup() {
    const SELLER_APP_STORE_MODULE_NAME = 'seller'
    const OPTIC_APP_STORE_MODULE_NAME = 'optic'
    const LAB_APP_STORE_MODULE_NAME = 'laboratory'
    const SP_APP_STORE_MODULE_NAME = 'software-partner'

    if (!store.hasModule(SELLER_APP_STORE_MODULE_NAME)) {
      store.registerModule(SELLER_APP_STORE_MODULE_NAME, sellerStoreModule)
    }
    if (!store.hasModule(OPTIC_APP_STORE_MODULE_NAME)) {
      store.registerModule(OPTIC_APP_STORE_MODULE_NAME, opticStoreModule)
    }
    if (!store.hasModule(LAB_APP_STORE_MODULE_NAME)) {
      store.registerModule(LAB_APP_STORE_MODULE_NAME, labStoreModule)
    }
    if (!store.hasModule(SP_APP_STORE_MODULE_NAME)) {
      store.registerModule(SP_APP_STORE_MODULE_NAME, softwarePartnerStoreModule)
    }

    onUnmounted(
      () =>
        store.hasModule(SELLER_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(SELLER_APP_STORE_MODULE_NAME) &&
        store.hasModule(LAB_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(LAB_APP_STORE_MODULE_NAME) &&
        store.hasModule(OPTIC_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(OPTIC_APP_STORE_MODULE_NAME) &&
        store.hasModule(SP_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(SP_APP_STORE_MODULE_NAME),
    )
    const { labs } = useLabsList()
    let selectedLab = ref(null)

    const { optics } = useOpticsList()
    let selectedOptic = ref(null)

    let selectedSoftwarePartner = ref(null)
    const { softwarePartners, fetchSoftwarePartners } =
      useSoftwarePartnersList()

    const {
      currentPage,
      isSortDirDesc,
      refSellersListTable,
      search,
      sortBy,
      tableColumns,
      totalSellers,
      sellers,
      deleteSeller,
      restoreSeller,
      perPage,
      perPageOptions,
    } = useSellersList()

    const role = store.state.auth.user.role
    const adminDocRef = store.state.auth.user.adminDocRef

    if (role === RoleEnum.LABORATORY_ADMIN) {
      store.dispatch('optic/fetchOptics', adminDocRef)
      sellers.value = []
    } else if (role === RoleEnum.SOFTWARE_ADMIN) {
      store.dispatch('laboratory/fetchLabs', adminDocRef)
      store.dispatch('optic/fetchOptics', adminDocRef)
    } else if (role === RoleEnum.SUPER_ADMIN) {
      fetchSoftwarePartners()
      store.dispatch('laboratory/fetchLabs')
      store.dispatch('optic/fetchOptics')
    } else {
      store.dispatch('seller/fetchSellers', adminDocRef)
    }

    const { sendSignLink } = useAuthLink()

    return {
      optics,
      selectedOptic,
      labs,
      selectedLab,
      selectedSoftwarePartner,
      softwarePartners,
      fetchSoftwarePartners,

      currentPage,
      isSortDirDesc,
      refSellersListTable,
      search,
      sortBy,
      tableColumns,
      totalSellers,
      sellers,
      deleteSeller,
      restoreSeller,
      perPage,
      perPageOptions,
      sendSignLink,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
